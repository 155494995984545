<template>
  <CampaignManager
    type="CAMPAIGN_MANAGER_STARTING_YESTERDAY"
    :func-get-opportunities="getOpportunityStarting"
    :show-date-picker="false"
    :start-date="yesterday"
  ></CampaignManager>
</template>
<script>
import CampaignManager from '@/views/private/modules/thirdParty/campaignManager/CampaignManager.vue';
import { getOpportunityStarting } from '@/services/modules/ThirdParty/opportunity';
import { TODAY } from '@/utils/dateTime/today';
import { addDays } from '@/utils/dateTime/addDays';

export default {
  name: 'CampaignManagerStartingYesterday',
  components: { CampaignManager },
  data() {
    return {
      yesterday: addDays(TODAY, -1),
    };
  },
  methods: {
    getOpportunityStarting,
  },
};
</script>
